<template>
  <div>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerHuman === '1'
        "
        to="/college/rate_workforce_g"
        large
        class="mr-7"
      >
        <span class="bottom-navi">ข้อมูลอัตราสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerStudent ===
            '1' && rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce"
        class="mr-15"
      >
        <span class="bottom-navi">ข้อมูลนักเรียนนักศึกษา </span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerCalStD ===
            '1' && rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce_pro"
        class="mr-14"
      >
        <span class="bottom-navi">ประมวลผลห้องเรียน </span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          rate_work_college_cal_roomCheck.status !== false &&
            rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce_cal"
        class="mr-15"
      >
        <span class="bottom-navi">ประมวลผลอัตรากำลัง </span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>
      <v-btn
        v-if="rate_work_college_cal_roomCheck.status !== false"
        to="/college/rate_workforce_report"
        class="mr-7"
      >
        <span class="bottom-navi">รายงานผลอัตรากำลัง </span>
        <v-icon>mdi-numeric-5-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-navigation
      color="info"
      horizontal
      :background-color="colorSec"
      dark
    >
      <v-btn to="/college/personnel_etc" large class="mr-10">
        <span class="bottom-navi">ข้อมูลบุคคล </span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerHuman === '1'
        "
        to="/college/rate_workforce_g"
        large
        class="mr-10"
      >
        <span class="bottom-navi">ข้อมูลอัตราสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container id="upgrade" fluid tag="section" class="text_google">
      <v-row>
        <v-col cols="12" md="12">
          <base-material-card color="primary">
            <template v-slot:heading>
              <h2 class="h1 font-weight-light text_google">
                <v-icon large left>mdi-file-send</v-icon>ข้อมูลสถานศึกษา
                ประเภทวิทยาลัย :
                {{ user.college_id_code_type_manpower }} ประจำปีการศึกษา :
                {{ period_colleges.period_college_year }} <br />
                สิ้นสุดวันที่บันทึกข้อมูล :
                {{
                  period_colleges.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h2>
            </template>

            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-card class="mx-auto pa-5">
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-simple-table class="elevation-1">
                        <template v-slot:default>
                          <thead>
                            <tr v-if="rate_work_gs.rate_work_g_college_code">
                              <th
                                colspan="5"
                                class="text-right red--text font-weight-bold"
                              >
                                ข้อมูล ณ วันที่ :{{
                                  rate_work_gs.rate_work_g_date_time
                                    | moment("add", "543 years")
                                    | moment("D MMMM YYYY")
                                }}
                                บันทึกข้อมูลอัตราสถานศึกษาขั้นตอนที่ 1 เรียบร้อย
                              </th>
                            </tr>
                            <tr class="bgth">
                              <th
                                width="40%"
                                class="text-center font-weight-bold white--text"
                              >
                                ประเภทอัตรา
                              </th>
                              <th
                                width="60%"
                                class="text-center font-weight-bold white--text"
                                colspan="4"
                              >
                                มีจริง
                              </th>
                            </tr>
                          </thead>

                          <tbody v-if="!rate_work_gs.rate_work_g_college_code">
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                1.ผอ.สถานศึกษา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ personnel_etcs.countDirector }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                2.รอง ผอ.สถานศึกษา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ personnel_etcs.countSe_director }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                3.ครูผู้สอน (จากระบบ)
                              </td>
                              <td class="text-center font-weight-black bgsum">
                                {{ personnel_etcs.countTeacher || 0}}
                              </td>
                              <td class="text-center font-weight-black bgsum">
                                {{ personnel_etcs.countgovernmentteach || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTemporary || 0}}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(personnel_etcs.countTeacher || 0) +
                                    parseInt(
                                      personnel_etcs.countgovernmentteach || 0
                                    ) +
                                    parseInt(personnel_etcs.countTemporary || 0)
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="red--text font-weight-bold">
                                *
                                ให้จำนวนบุคลากรแยกตามประเภทวิชาตรงกับจำนวนในระบบ
                                ครูผู้สอน(จากระบบ)
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                ข้าราชการ
                                <br />
                                <div
                                  v-if="
                                    checkTeach === personnel_etcs.countTeacher
                                  "
                                >
                                  <span class="info--text font-weight-bold">
                                    {{ checkTeach }}</span
                                  >
                                </div>

                                <div
                                  v-if="
                                    checkTeach !== personnel_etcs.countTeacher
                                  "
                                >
                                  <span class="red--text font-weight-bold">
                                    {{ checkTeach }} คน</span
                                  >
                                </div>
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                พนักงานราชการ

                                <br />
                                <div
                                  v-if="
                                    checkGovTeach ==
                                      personnel_etcs.countgovernmentteach
                                  "
                                >
                                  <span class="info--text font-weight-bold">
                                    {{ checkGovTeach }} คน</span
                                  >
                                </div>

                                <div
                                  v-else-if="
                                    checkGovTeach !==
                                      personnel_etcs.countgovernmentteach
                                  "
                                >
                                  <span class="red--text font-weight-bold">
                                    {{ checkGovTeach }} คน</span
                                  >
                                </div>
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                ครูอัตราจ้าง
                                <div
                                  v-if="
                                    checkGovTeachTem ==
                                      personnel_etcs.countTemporary
                                  "
                                >
                                  <span class="info--text font-weight-bold">
                                    {{ checkGovTeachTem }} คน</span
                                  >
                                </div>

                                <div
                                  v-else-if="
                                    checkGovTeachTem !==
                                      personnel_etcs.countTemporary
                                  "
                                >
                                  <span class="red--text font-weight-bold">
                                    {{ checkGovTeachTem }} คน</span
                                  >
                                </div>
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                รวม
<br>
                            <span class="yellow--text"> {{ checkTeachSum }}</span>   
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">00-ประเภทวิชาสามัญ</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach00 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov00 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem00 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach00 || 0) +
                                      parseInt(personnel_etcs.countGov00 || 0) +
                                      parseInt(personnel_etcs.countTem00 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">01-ประเภทวิชาอุตสาหกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach01 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov01 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem01 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach01 || 0) +
                                      parseInt(personnel_etcs.countGov01 || 0) +
                                      parseInt(personnel_etcs.countTem01 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">02-ประเภทวิชาพาณิชยกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach02 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov02 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem02 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach02 || 0) +
                                      parseInt(personnel_etcs.countGov02 || 0) +
                                      parseInt(personnel_etcs.countTem02 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">03-ประเภทวิชาศิลปกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach03 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov03 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem03 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach03 || 0) +
                                      parseInt(personnel_etcs.countGov03 || 0) +
                                      parseInt(personnel_etcs.countTem03 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">04-ประเภทวิชาคหกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach04 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov04 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem04 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach04 || 0) +
                                      parseInt(personnel_etcs.countGov04 || 0) +
                                      parseInt(personnel_etcs.countTem04 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">05-ประเภทวิชาเกษตรกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach05 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov05 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem05 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach05 || 0) +
                                      parseInt(personnel_etcs.countGov05 || 0) +
                                      parseInt(personnel_etcs.countTem05 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">06-ประเภทวิชาประมง</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach06 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov06 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem06 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach06 || 0) +
                                      parseInt(personnel_etcs.countGov06 || 0) +
                                      parseInt(personnel_etcs.countTem06 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                07-ประเภทวิชาอุตสาหกรรมการท่องเที่ยว
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach07 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov07 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem07 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach07 || 0) +
                                      parseInt(personnel_etcs.countGov07 || 0) +
                                      parseInt(personnel_etcs.countTem07 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                08-ประเภทวิชาอุตสาหกรรมสิ่งทอ
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach08 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov08 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem08 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach08 || 0) +
                                      parseInt(personnel_etcs.countGov08 || 0) +
                                      parseInt(personnel_etcs.countTem08 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                09-ประเภทวิชาเทคโนโลยีสารสนเทศ
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach09 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov09 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem09 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach09 || 0) +
                                      parseInt(personnel_etcs.countGov09 || 0) +
                                      parseInt(personnel_etcs.countTem09 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                10-อุตสาหกรรมบันเทิงและดนตรี
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach10 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov10 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem10 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach10 || 0) +
                                      parseInt(personnel_etcs.countGov10 || 0) +
                                      parseInt(personnel_etcs.countTem10 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">11-พาณิชยนาวี</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach11 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov11 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem11 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach11 || 0) +
                                      parseInt(personnel_etcs.countGov11 || 0) +
                                      parseInt(personnel_etcs.countTem11 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                12-เทคโนโลยีและนวัตกรรมเกษตรอุตสาหกรรม
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach12 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov12 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem12 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach12 || 0) +
                                      parseInt(personnel_etcs.countGov12 || 0) +
                                      parseInt(personnel_etcs.countTem12 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">66-หลักสูตรระยะสั้น*</td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTeach66 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countGov66 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ personnel_etcs.countTem66 || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(personnel_etcs.countTeach66 || 0) +
                                      parseInt(personnel_etcs.countGov66 || 0) +
                                      parseInt(personnel_etcs.countTem66 || 0)
                                  )
                                }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                4.บุคลากรสนับสนุนการสอน
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ personnel_etcs.count38 || 0 }}
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ personnel_etcs.countgovernmentsp || 0 }}
                              </td>
                            </tr>
                            <tr class="bgsum">
                              <td
                                class="text-center font-weight-black bgth white--text"
                              >
                                รวมอัตรา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black"
                              >
                                <h3>
                                  ใช้ในการคำนวณอัตรากำลัง
                                  ข้าราชการ/พนักงานราชการ จำนวน :
                                  {{
                                    parseInt(
                                      parseInt(
                                        personnel_etcs.countDirector || 0
                                      ) +
                                        parseInt(
                                          personnel_etcs.countSe_director || 0
                                        ) +
                                        parseInt(
                                          personnel_etcs.countTeacher || 0
                                        ) +
                                        parseInt(
                                          personnel_etcs.countgovernmentteach ||
                                            0
                                        ) +
                                        parseInt(personnel_etcs.count38 || 0) +
                                        parseInt(
                                          personnel_etcs.countgovernmentsp || 0
                                        )
                                    )
                                  }}
                                  คน
                                </h3>
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                5.ลูกจ้างประจำ/ชั่วคราว
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black"
                              >
                                {{ personnel_etcs.countPermanent || 0 }}
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black"
                              >
                                {{ personnel_etcs.countTemporary_job || 0 }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="text-center font-weight-black bgth white--text"
                              >
                                รวมอัตราของสถานศึกษาทั้งสิ้น
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black"
                              >
                                <h4>
                                  จำนวนรวมทั้งสิ้น :
                                  {{ parseInt(personnel_etcs.countAll || 0) }}
                                  คน
                                </h4>
                              </td>
                            </tr>
                          </tbody>

                          <!-- Show After Insert Data -->

                          <tbody v-if="rate_work_gs.rate_work_g_college_code">
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                1.ผอ.สถานศึกษา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ rate_work_gs.rate_work_g_dr }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                2.รอง ผอ.สถานศึกษา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ rate_work_gs.rate_work_g_sedr }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">3.ครูผู้สอน</td>
                              <td class="text-center font-weight-black bgsum">
                                {{ sumTeacher }}
                              </td>
                              <td class="text-center font-weight-black bgsum">
                                {{ sumGovTeach }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ sumTeachTem }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(sumTeacher) +
                                    parseInt(sumGovTeach) +
                                    parseInt(sumTeachTem)
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                ข้าราชการ
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                พนักงานราชการ
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                ครูอัตราจ้าง
                              </td>
                              <td
                                class="text-center font-weight-bold bgth white--text"
                              >
                                รวม
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">00-ประเภทวิชาสามัญ</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_gs_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_gs_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_gs_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_gs_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_gs_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_gs_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">01-ประเภทวิชาอุตสาหกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_is_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_is_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_is_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_is_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_is_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_is_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">02-ประเภทวิชาพาณิชยกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_cm_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_cm_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_cm_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_cm_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_cm_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_cm_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">03-ประเภทวิชาศิลปกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_art_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_art_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_art_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_art_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_art_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_art_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">04-ประเภทวิชาคหกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_em_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_em_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_em_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_em_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_em_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_em_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">05-ประเภทวิชาเกษตรกรรม</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_ag_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_ag_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_ag_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_ag_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_ag_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_ag_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">06-ประเภทวิชาประมง</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_fish_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_fish_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_fish_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_fish_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_fish_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_fish_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                07-ประเภทวิชาอุตสาหกรรมการท่องเที่ยว
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_tour_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_tour_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_tour_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_tour_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_tour_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_tour_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                08-ประเภทวิชาอุตสาหกรรมสิ่งทอ
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_textile_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_textile_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_textile_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_textile_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_textile_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_textile_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                09-ประเภทวิชาเทคโนโลยีสารสนเทศ
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_insy_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_insy_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_insy_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_insy_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_insy_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_insy_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                10-อุตสาหกรรมบันเทิงและดนตรี
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_entertain_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_entertain_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_entertain_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_entertain_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_entertain_tb ||
                                          0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_entertain_tc ||
                                          0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">11-พาณิชยนาวี</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_navy_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_navy_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_navy_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_navy_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_navy_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_navy_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">
                                12-เทคโนโลยีและนวัตกรรมเกษตรอุตสาหกรรม
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_agriInno_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_agriInno_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_agriInno_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_agriInno_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_agriInno_tc ||
                                          0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_agriInno_tc ||
                                          0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pl-10">66-หลักสูตรระยะสั้น*</td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_sc_ta || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_sc_tb || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{ rate_work_gs.rate_work_g_sc_tc || 0 }}
                              </td>
                              <td class="text-center font-weight-black">
                                {{
                                  parseInt(
                                    parseInt(
                                      rate_work_gs.rate_work_g_sc_ta || 0
                                    ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_sc_tb || 0
                                      ) +
                                      parseInt(
                                        rate_work_gs.rate_work_g_sc_tc || 0
                                      )
                                  )
                                }}
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                4.บุคลากรสนับสนุนการสอน
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ rate_work_gs.rate_work_g_sup_ta || 0 }}
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black bgsum"
                              >
                                {{ rate_work_gs.rate_work_g_sup_tb || 0 }}
                              </td>
                            </tr>
                            <tr class="bgsum">
                              <td
                                class="text-center font-weight-black bgth white--text"
                              >
                                รวมอัตรา
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black"
                              >
                                <h3>
                                  ใช้ในการคำนวณอัตรากำลัง
                                  ข้าราชการ/พนักงานราชการ จำนวน :
                                  {{
                                    parseInt(
                                      parseInt(
                                        rate_work_gs.rate_work_g_dr || 0
                                      ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sedr || 0
                                        ) +
                                        parseInt(sumTeacher || 0) +
                                        parseInt(sumGovTeach || 0) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sup_ta || 0
                                        ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sup_tb || 0
                                        )
                                    )
                                  }}
                                  คน
                                </h3>
                              </td>
                            </tr>
                            <tr class="bgrow">
                              <td class="font-weight-black">
                                5.ลูกจ้างประจำ/ชั่วคราว
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black"
                              >
                                {{ rate_work_gs.rate_work_g_pte_ta || 0 }}
                              </td>
                              <td
                                colspan="2"
                                class="text-center font-weight-black"
                              >
                                {{ rate_work_gs.rate_work_g_pte_tb || 0 }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="text-center font-weight-black bgth white--text"
                              >
                                รวมอัตราของสถานศึกษาทั้งสิ้น
                              </td>
                              <td
                                colspan="4"
                                class="text-center font-weight-black"
                              >
                                <h4>
                                  จำนวนรวมทั้งสิ้น :
                                  {{
                                    parseInt(
                                      parseInt(
                                        rate_work_gs.rate_work_g_dr || 0
                                      ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sedr || 0
                                        ) +
                                        parseInt(sumTeacher || 0) +
                                        parseInt(sumGovTeach || 0) +
                                        parseInt(sumTeachTem || 0) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sup_ta || 0
                                        ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_sup_tb || 0
                                        ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_pte_ta || 0
                                        ) +
                                        parseInt(
                                          rate_work_gs.rate_work_g_pte_tb || 0
                                        )
                                    )
                                  }}
                                  คน
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div v-if="!rate_work_gs.rate_work_g_college_code">
                        <div class="text-right mt-2">
                          ข้อมูล ณ วันที่
                          {{
                            date_today
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                          <v-btn
                            color="primary"
                            large
                            @click="rate_work_gSubmit()"
                          >
                            <v-icon>mdi-content-save</v-icon>
                            ยืนยันข้อมูลจำนวนอัตรา</v-btn
                          >
                          <br />
                        </div>
                        <div class="text-right mt-2 red--text">
                          *จำนวนอัตราที่ท่านยืนยันตามข้อมูลดังกล่าวข้างต้น
                          จะไม่สามารถแก้ไขเปลี่ยนแปลงได้แล้ว
                          กรุณาตรวจสอบให้ถูกต้อง
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      user: [],
      users: [],
      years_select: "",
      year_s: [2565, 2566, 2567, 2568, 2569, 2570],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      period_colleges: [],
      loading: true,
      dialogLoading: true,
      value: 0,
      rate_work_college_cal_roomCheck: [],
      sys_config_collegemanpowers: [],
      rate_work_g: [],
      personnel_etcs: [],
      addrate_work_g: {},
      rate_work_gs: [],
      sumTeacher: [],
      sumGovTeach: [],
      sumTeachTem: [],
      sumTotalTeach: [],
      rate_work_gCheck: [],
      checkTeach: [],
      checkGovTeach: [],
      checkGovTeachTem: [],
      checkTeachSum: []
    };
  },

  async mounted() {
    await this.sweetAlertLoading()
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = result.data;
   
    await this.period_collegeQuery();
    await this.sys_config_collegemanpowerQueryAll();
    await this.rate_work_college_cal_roomQueryCheck();
    await this.rate_work_gQuery();
    await this.personnel_etcCountQuery();
    await this.rate_work_gCheckQrury();
    Swal.close();
  },

  methods: {
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },


    async rate_work_gCheckQrury() {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_college_code: this.user.user_code,
        rate_work_g_year: this.period_colleges.period_college_year
      });
      this.rate_work_gCheck = result.data;     
    },

    async rate_work_gSubmit() {
      this.addrate_work_g.ApiKey = this.ApiKey;
      this.addrate_work_g.rate_work_g_year = this.period_colleges.period_college_year;
      this.addrate_work_g.rate_work_g_college_code = this.user.user_name;
      this.addrate_work_g.rate_work_g_date_time = this.date_today;

      this.addrate_work_g.rate_work_g_dr =
        this.personnel_etcs.countDirector || 0;
      this.addrate_work_g.rate_work_g_sedr =
        this.personnel_etcs.countSe_director || 0;

      this.addrate_work_g.rate_work_g_gs_ta =
        this.personnel_etcs.countTeach00 || 0;
      this.addrate_work_g.rate_work_g_gs_tb =
        this.personnel_etcs.countGov00 || 0;
      this.addrate_work_g.rate_work_g_gs_tc =
        this.personnel_etcs.countTem00 || 0;

      this.addrate_work_g.rate_work_g_is_ta =
        this.personnel_etcs.countTeach01 || 0;
      this.addrate_work_g.rate_work_g_is_tb =
        this.personnel_etcs.countGov01 || 0;
      this.addrate_work_g.rate_work_g_is_tc =
        this.personnel_etcs.countTem01 || 0;

      this.addrate_work_g.rate_work_g_cm_ta =
        this.personnel_etcs.countTeach02 || 0;
      this.addrate_work_g.rate_work_g_cm_tb =
        this.personnel_etcs.countGov02 || 0;
      this.addrate_work_g.rate_work_g_cm_tc =
        this.personnel_etcs.countTem02 || 0;

      this.addrate_work_g.rate_work_g_art_ta =
        this.personnel_etcs.countTeach03 || 0;
      this.addrate_work_g.rate_work_g_art_tb =
        this.personnel_etcs.countGov03 || 0;
      this.addrate_work_g.rate_work_g_art_tc =
        this.personnel_etcs.countTem03 || 0;

      this.addrate_work_g.rate_work_g_em_ta =
        this.personnel_etcs.countTeach04 || 0;
      this.addrate_work_g.rate_work_g_em_tb =
        this.personnel_etcs.countGov04 || 0;
      this.addrate_work_g.rate_work_g_em_tc =
        this.personnel_etcs.countTem04 || 0;

      this.addrate_work_g.rate_work_g_ag_ta =
        this.personnel_etcs.countTeach05 || 0;
      this.addrate_work_g.rate_work_g_ag_tb =
        this.personnel_etcs.countGov05 || 0;
      this.addrate_work_g.rate_work_g_ag_tc =
        this.personnel_etcs.countTem05 || 0;

      this.addrate_work_g.rate_work_g_fish_ta =
        this.personnel_etcs.countTeach06 || 0;
      this.addrate_work_g.rate_work_g_fish_tb =
        this.personnel_etcs.countGov06 || 0;
      this.addrate_work_g.rate_work_g_fish_tc =
        this.personnel_etcs.countTem06 || 0;

      this.addrate_work_g.rate_work_g_tour_ta =
        this.personnel_etcs.countTeach07 || 0;
      this.addrate_work_g.rate_work_g_tour_tb =
        this.personnel_etcs.countGov07 || 0;
      this.addrate_work_g.rate_work_g_tour_tc =
        this.personnel_etcs.countTem07 || 0;

      this.addrate_work_g.rate_work_g_textile_ta =
        this.personnel_etcs.countTeach08 || 0;
      this.addrate_work_g.rate_work_g_textile_tb =
        this.personnel_etcs.countGov08 || 0;
      this.addrate_work_g.rate_work_g_textile_tc =
        this.personnel_etcs.countTem08 || 0;

      this.addrate_work_g.rate_work_g_insy_ta =
        this.personnel_etcs.countTeach09 || 0;
      this.addrate_work_g.rate_work_g_insy_tb =
        this.personnel_etcs.countGov09 || 0;
      this.addrate_work_g.rate_work_g_insy_tc =
        this.personnel_etcs.countTem09 || 0;

      this.addrate_work_g.rate_work_g_entertain_ta =
        this.personnel_etcs.countTeach10 || 0;
      this.addrate_work_g.rate_work_g_entertain_tb =
        this.personnel_etcs.countGov10 || 0;
      this.addrate_work_g.rate_work_g_entertain_tc =
        this.personnel_etcs.countTem10 || 0;

      this.addrate_work_g.rate_work_g_navy_ta =
        this.personnel_etcs.countTeach11 || 0;
      this.addrate_work_g.rate_work_g_navy_tb =
        this.personnel_etcs.countGov11 || 0;
      this.addrate_work_g.rate_work_g_navy_tc =
        this.personnel_etcs.countTem11 || 0;

      this.addrate_work_g.rate_work_g_agriInno_ta =
        this.personnel_etcs.countTeach12 || 0;
      this.addrate_work_g.rate_work_g_agriInno_tb =
        this.personnel_etcs.countGov12 || 0;
      this.addrate_work_g.rate_work_g_agriInno_tc =
        this.personnel_etcs.countTem12 || 0;

      this.addrate_work_g.rate_work_g_sc_ta =
        this.personnel_etcs.countTeach66 || 0;
      this.addrate_work_g.rate_work_g_sc_tb =
        this.personnel_etcs.countGov66 || 0;
      this.addrate_work_g.rate_work_g_sc_tc =
        this.personnel_etcs.countTem66 || 0;

      this.addrate_work_g.rate_work_g_sup_ta = this.personnel_etcs.count38 || 0;
      this.addrate_work_g.rate_work_g_sup_tb =
        this.personnel_etcs.countgovernmentsp || 0;

      this.addrate_work_g.rate_work_g_pte_ta =
        this.personnel_etcs.countPermanent || 0;
      this.addrate_work_g.rate_work_g_pte_tb =
        this.personnel_etcs.countTemporary_job || 0;
      this.addrate_work_g.rate_work_g_pte_tc =
        this.personnel_etcs.countTemporary || 0;

      Swal.fire({
        title: "คุณต้องการยืนยันข้อมูลจำนวนอัตรา",
        text: "กรุณาตรวจสอบให้ถูกต้องการกดยืนยัน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post(
            "rate_work_g.insert.php",
            this.addrate_work_g
          )          
          
          ;
          if (result.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.rate_work_gQuery();
            await this.personnel_etcCountQuery();
            await this.rate_work_gCheckQrury();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async sys_config_collegemanpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sys_config_collegemanpower.php", {
          ApiKey: this.ApiKey,
          sys_config_CollegemanpowerID: "1"
        })
        .finally(() => (this.loading = false));
      this.sys_config_collegemanpowers = result.data;
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHuman != "1"
      )
        this.$router.push("/college/rate_workforce_report");
    },

    async rate_work_college_cal_roomQueryCheck() {
      let result = await this.$http.post("rate_work_college_cal_room.php", {
        ApiKey: this.ApiKey,
        rate_work_ccr_code: this.user.user_code,
        rate_work_ccr_year: this.period_colleges.period_college_year,
        rate_work_ccr_check: "OK"
      });
      this.rate_work_college_cal_roomCheck = result.data;
    },

    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "update_college"
      });
      this.period_colleges = result_period_college.data;
    },

    async rate_work_gQuery() {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_year: this.period_colleges.period_college_year,
        rate_work_g_college_code: this.user.user_name
      });
      this.rate_work_gs = result.data;

      this.sumTeacher =
        parseInt(this.rate_work_gs.rate_work_g_gs_ta) +
        parseInt(this.rate_work_gs.rate_work_g_is_ta) +
        parseInt(this.rate_work_gs.rate_work_g_cm_ta) +
        parseInt(this.rate_work_gs.rate_work_g_art_ta) +
        parseInt(this.rate_work_gs.rate_work_g_em_ta) +
        parseInt(this.rate_work_gs.rate_work_g_ag_ta) +
        parseInt(this.rate_work_gs.rate_work_g_fish_ta) +
        parseInt(this.rate_work_gs.rate_work_g_tour_ta) +
        parseInt(this.rate_work_gs.rate_work_g_textile_ta) +
        parseInt(this.rate_work_gs.rate_work_g_insy_ta) +
        parseInt(this.rate_work_gs.rate_work_g_entertain_ta) +
        parseInt(this.rate_work_gs.rate_work_g_navy_ta) +
        parseInt(this.rate_work_gs.rate_work_g_agriInno_ta) +
        parseInt(this.rate_work_gs.rate_work_g_sc_ta);

      this.sumGovTeach =
        parseInt(this.rate_work_gs.rate_work_g_gs_tb) +
        parseInt(this.rate_work_gs.rate_work_g_is_tb) +
        parseInt(this.rate_work_gs.rate_work_g_cm_tb) +
        parseInt(this.rate_work_gs.rate_work_g_art_tb) +
        parseInt(this.rate_work_gs.rate_work_g_em_tb) +
        parseInt(this.rate_work_gs.rate_work_g_ag_tb) +
        parseInt(this.rate_work_gs.rate_work_g_fish_tb) +
        parseInt(this.rate_work_gs.rate_work_g_tour_tb) +
        parseInt(this.rate_work_gs.rate_work_g_textile_tb) +
        parseInt(this.rate_work_gs.rate_work_g_insy_tb) +
        parseInt(this.rate_work_gs.rate_work_g_entertain_tb) +
        parseInt(this.rate_work_gs.rate_work_g_navy_tb) +
        parseInt(this.rate_work_gs.rate_work_g_agriInno_tb) +
        parseInt(this.rate_work_gs.rate_work_g_sc_tb);

      this.sumTeachTem =
        parseInt(this.rate_work_gs.rate_work_g_gs_tc) +
        parseInt(this.rate_work_gs.rate_work_g_is_tc) +
        parseInt(this.rate_work_gs.rate_work_g_cm_tc) +
        parseInt(this.rate_work_gs.rate_work_g_art_tc) +
        parseInt(this.rate_work_gs.rate_work_g_em_tc) +
        parseInt(this.rate_work_gs.rate_work_g_ag_tc) +
        parseInt(this.rate_work_gs.rate_work_g_fish_tc) +
        parseInt(this.rate_work_gs.rate_work_g_tour_tc) +
        parseInt(this.rate_work_gs.rate_work_g_textile_tc) +
        parseInt(this.rate_work_gs.rate_work_g_insy_tc) +
        parseInt(this.rate_work_gs.rate_work_g_entertain_tc) +
        parseInt(this.rate_work_gs.rate_work_g_navy_tc) +
        parseInt(this.rate_work_gs.rate_work_g_agriInno_tc) +
        parseInt(this.rate_work_gs.rate_work_g_sc_tc);
    },

    async personnel_etcCountQuery() {
      let result = await this.$http.post("personnel_etc.std.php", {
        ApiKey: this.ApiKey,
        personnel_etc_college_code: this.user.user_name
      });
      this.personnel_etcs = result.data;
      let checkTeach =
        parseInt(this.personnel_etcs.countTeach00 || 0) +
        parseInt(this.personnel_etcs.countTeach01 || 0) +
        parseInt(this.personnel_etcs.countTeach02 || 0) +
        parseInt(this.personnel_etcs.countTeach03 || 0) +
        parseInt(this.personnel_etcs.countTeach04 || 0) +
        parseInt(this.personnel_etcs.countTeach05 || 0) +
        parseInt(this.personnel_etcs.countTeach06 || 0) +
        parseInt(this.personnel_etcs.countTeach07 || 0) +
        parseInt(this.personnel_etcs.countTeach08 || 0) +
        parseInt(this.personnel_etcs.countTeach09 || 0) +
        parseInt(this.personnel_etcs.countTeach10 || 0) +
        parseInt(this.personnel_etcs.countTeach11 || 0) +
        parseInt(this.personnel_etcs.countTeach12 || 0) +
        parseInt(this.personnel_etcs.countTeach66 || 0);
      this.checkTeach = checkTeach;

      let checkGovTeach =
        parseInt(this.personnel_etcs.countGov00 || 0) +
        parseInt(this.personnel_etcs.countGov01 || 0) +
        parseInt(this.personnel_etcs.countGov02 || 0) +
        parseInt(this.personnel_etcs.countGov03 || 0) +
        parseInt(this.personnel_etcs.countGov04 || 0) +
        parseInt(this.personnel_etcs.countGov05 || 0) +
        parseInt(this.personnel_etcs.countGov06 || 0) +
        parseInt(this.personnel_etcs.countGov07 || 0) +
        parseInt(this.personnel_etcs.countGov08 || 0) +
        parseInt(this.personnel_etcs.countGov09 || 0) +
        parseInt(this.personnel_etcs.countGov10 || 0) +
        parseInt(this.personnel_etcs.countGov11 || 0) +
        parseInt(this.personnel_etcs.countGov12 || 0) +
        parseInt(this.personnel_etcs.countGov66 || 0);
      this.checkGovTeach = checkGovTeach;

      let checkGovTeachTem =
        parseInt(this.personnel_etcs.countTem00 || 0) +
        parseInt(this.personnel_etcs.countTem01 || 0) +
        parseInt(this.personnel_etcs.countTem02 || 0) +
        parseInt(this.personnel_etcs.countTem03 || 0) +
        parseInt(this.personnel_etcs.countTem04 || 0) +
        parseInt(this.personnel_etcs.countTem05 || 0) +
        parseInt(this.personnel_etcs.countTem06 || 0) +
        parseInt(this.personnel_etcs.countTem07 || 0) +
        parseInt(this.personnel_etcs.countTem08 || 0) +
        parseInt(this.personnel_etcs.countTem09 || 0) +
        parseInt(this.personnel_etcs.countTem10 || 0) +
        parseInt(this.personnel_etcs.countTem11 || 0) +
        parseInt(this.personnel_etcs.countTem12 || 0) +
        parseInt(this.personnel_etcs.countTem66 || 0);
      this.checkGovTeachTem = checkGovTeachTem;
      this.checkTeachSum =
        parseInt(this.checkTeach) +
        parseInt(this.checkGovTeach) +
        parseInt(this.checkGovTeachTem);
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    year_now() {
      let today = new Date();
      let yyyy = today.getFullYear() + 543;
      today = yyyy;
      return today;
    },
    color() {
      return "teal darken-4";
    },
    colorSec() {
      return "brown-darken-4";
    }
  }
};
</script>
<style>
.bottom-navi {
  font-size: 12pt;
}

.bgrow {
  background-color: #ddf6c9;
}

.bgth {
  background-color: #0a6938;
}

.bgsum {
  background-color: #eead44;
}
</style>
